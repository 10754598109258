import { useEffect, useState } from 'react';
import Select2 from 'react-select';
import { Api } from '../../../../services';

const MapSelectInput = ({ onMapChange }) => {
  const [selectedMap, setSelectedMap] = useState({ label: 'Select a Map', value: '' });
  const [mapOptions, setMapOptions] = useState([]);

  const fetchMaps = async () => {
    try {
      const res = await Api.get('maps?top=1000');
      const mapItems = res.data.items;
      const options = mapItems.map((map) => ({
        value: map.id,
        label: map.name,
      }));
      setMapOptions(options);
    } catch (error) {
      console.error('Error fetching map options:', error);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedMap(selectedOption);
    if (onMapChange) {
      onMapChange(selectedOption.value, selectedOption.label);
    }
  };

  useEffect(() => {
    fetchMaps();
  }, []);

  return (
    <Select2
      options={mapOptions}
      value={selectedMap}
      placeholder="Select a Map"
      onChange={handleChange}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          border: '1px solid lightgray',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid lightgray',
          },
          '&:focus-within': {
            borderColor: 'var(--c-primary)',
            boxShadow: '0 0 0 1px var(--c-primary)',
          },
        }),
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
          color: state.isSelected ? 'white' : 'black',
          '&:hover': {
            backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
          },
        }),
      }}
    />
  );
};

export default MapSelectInput;
