import '../../MapMenu.scss';
import { Card, Form } from 'react-bootstrap';
import { Icon, Keywords, Text } from '../../..';

export function MapInfoOverlay({ mapData, visible, setSelected }) {
  return (
    <Form className={visible ? 'object-wrapper' : 'object-wrapper-none'}>
      <Card.Body className="object-edit d-flex flex-wrap flex-column align-items-left justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <Text className="title-text fw-bold fs-md">Description:</Text>
          <div>
            <Icon
              name={'add'}
              title="Close"
              className="sonarIcon-rotate-45 align-sub nav-text-icon"
              variant="light"
              disabled={false}
              showPointer={true}
              size="md"
              onClick={() => {
                setSelected('');
              }}
            />
          </div>
        </div>
        <Form.Control
          className="mb-3"
          as="textarea"
          disabled={true}
          value={
            mapData.features[0].properties.Description
              ? mapData.features[0].properties.Description
              : 'No description available.'
          }
        />
        <Text className="title-text fw-bold fs-md">Keywords:</Text>

        <Keywords
          isAddingKeyword={false}
          initKeywords={mapData.features[0].properties.Keywords}
          canDeleteKeywords={false}
          onChange={() => {}}
        />
      </Card.Body>
    </Form>
  );
}
