import '../../MapMenu.scss';
import 'leaflet.markercluster';
import { capitalize } from '@utils';
import { getFormattedDateTime } from '@utils';
import * as L from 'leaflet';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon, Text } from '../../../../components';
import { FilterContainer } from '../../../../containers';
import { V2FilterMapList } from '../../../AssetFilter/functions/V2FilterMapList';

export function MapInfoList({
  type,
  listData,
  listMarkers,
  listRefs,
  fetchData,
  visible,
  disabledFilters,
  setViewItem,
  setSelected,
  reset,
}) {
  const [title, setTitle] = useState<string>(null);
  const [sort, setSort] = useState<any>({ field: 'Name', ascending: true });
  const [search, setSearch] = useState<string>('');
  const [andOr, setAndOr] = useState<string>('and');
  const [assetFilters, setAssetFilters] = useState<any>([]);
  const history = useHistory();
  const isFirstRender = useRef(true);

  const debounceFetchData = useCallback(
    debounce((assetFilters, andOr, sort, search, selected) => {
      fetchData(V2FilterMapList(assetFilters, andOr, sort, search), selected);
    }, 500),
    [],
  );

  useEffect(() => {
    if (type) {
      type === 'stationary' ? setTitle('Stationary Assets') : setTitle(capitalize(type) + 's');
    } else {
      setTitle(null);
    }
  }, [type]);

  useEffect(() => {
    if (type !== 'asset') {
      debounceFetchData(assetFilters, andOr, sort, search, type);
    } else if (type !== '') {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      } else {
        if (search !== '' || assetFilters.length > 0) {
          debounceFetchData(assetFilters, andOr, sort, search, type);
        }
        if (search === '' && assetFilters.length === 0) {
          reset();
        }
      }
    }
  }, [type, assetFilters, andOr, sort, search]);

  //Handle asset filter data changes
  const handleFilterChange = (e, type) => {
    if (JSON.stringify(e) !== JSON.stringify(assetFilters)) {
      setAssetFilters(e);
    }
    if (type !== andOr) {
      setAndOr(type);
    }
  };

  function fireClickEvent(marker) {
    if (type === 'asset') {
      var visibleLayer = listMarkers.getVisibleParent(marker);
      // if clustered, drill down into marker clusters
      if (visibleLayer instanceof L.MarkerCluster) {
        listMarkers.zoomToShowLayer(marker, () => {
          marker.fire('click');
        });
      } else {
        // Marker is already visible
        marker.fire('click');
      }
    } else {
      // Click for other object types
      marker.fire('click');
    }
  }

  return (
    <div className={visible ? 'object-wrapper' : 'object-wrapper-none'}>
      <div className={visible ? 'pinned-controls' : 'pinned-controls-none'}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <InputGroup>
            <div className="d-flex flex-row">
              <Text className="title-text fw-bold fs-md me-3">{title ? title : ''}</Text>
              {type === 'asset' && (
                <FilterContainer
                  key={1}
                  onChange={(e, type) => handleFilterChange(e, type)}
                  disabledFilters={disabledFilters}
                  filterReportType={'asset-filter'}
                  ignoreDefaultFilters={true}
                />
              )}
            </div>
            <Form.Control
              id={'Map Search'}
              className="map-search"
              autoComplete="off"
              placeholder="Search by Name"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Icon
              name={'add'}
              title="Close"
              className="sonarIcon-rotate-45 align-sub nav-text-icon"
              variant="light"
              disabled={false}
              size="md"
              onClick={() => {
                setSelected(null);
              }}
            />
          </InputGroup>
        </div>
        {type !== 'overlay' && (
          <div className="d-flex flex-row align-items-center justify-content-between"></div>
        )}
      </div>
      {listData && (
        <div className="list-container">
          <div key={'headers'}>
            <div className="header-btns">
              <div className="name-col">
                <Button
                  title="Sort by Name"
                  className="btn-light d-flex justify-content-between align-items-center nav-text-icon me-3"
                  variant="light"
                  onClick={() => {
                    if (sort.field !== 'Name') {
                      setSort({ field: 'Name', ascending: true });
                    } else {
                      sort.ascending
                        ? setSort({ field: 'Name', ascending: false })
                        : setSort({ field: 'Name', ascending: true });
                    }
                  }}
                >
                  Name &nbsp;
                  {sort.field === 'Name' && (
                    <i
                      className={
                        sort.ascending
                          ? 'fa fa-arrow-up fs-sm fw-lighter'
                          : 'fa fa-arrow-down fs-sm fw-lighter'
                      }
                    />
                  )}
                </Button>
              </div>
              {type !== 'place' && (
                <div className="checkin-col">
                  <Button
                    title="Sort by Name"
                    className="btn-light d-flex justify-content-between align-items-center nav-text-icon me-3"
                    variant="light"
                    onClick={() => {
                      if (sort.field !== 'LastCheckIn') {
                        setSort({ field: 'LastCheckIn', ascending: true });
                      } else {
                        sort.ascending
                          ? setSort({ field: 'LastCheckIn', ascending: false })
                          : setSort({ field: 'LastCheckIn', ascending: true });
                      }
                    }}
                  >
                    Last Check-In &nbsp;
                    {sort.field === 'LastCheckIn' && (
                      <i
                        className={
                          sort.ascending
                            ? 'fa fa-arrow-up fs-sm fw-lighter'
                            : 'fa fa-arrow-down fs-sm fw-lighter'
                        }
                      />
                    )}
                  </Button>
                </div>
              )}
              <div className="btns-col"></div>
            </div>
          </div>
          {listData?.features.map((feature, index) => {
            return (
              <div
                id={feature.properties.Id}
                key={index}
                onClick={() => {
                  let marker = listRefs[feature.properties.Id];
                  fireClickEvent(marker);
                }}
              >
                <div className="list-item">
                  <div className="list-info">
                    <div className="name-col">{feature.properties.Name}</div>
                    {feature.properties.LastCheckIn && (
                      <div className="checkin-col">
                        {getFormattedDateTime(feature.properties.LastCheckIn)}
                      </div>
                    )}
                  </div>
                  <div className="btns-col">
                    {(type === 'asset' || type === 'stationary') && (
                      <Button
                        title="Go to Asset"
                        className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                        variant="light"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          history.push('/assets/' + feature.properties.Id);
                          e.stopPropagation();
                        }}
                      >
                        <i className={`sonarIcon sonarIcon-open-in fs-md`} />
                      </Button>
                    )}
                    <Button
                      title="Show more"
                      className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                      variant="light"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        setViewItem(feature);
                        e.stopPropagation();
                      }}
                    >
                      <i className={`sonarIcon sonarIcon-h-dots-1 fs-md`} />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
