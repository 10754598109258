import '../../../../scss/variables.scss';
import '../../MapMenu.scss';
import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { MapCreateGateway } from './MapCreateGateway';
import { MapCreatePlace } from './MapCreatePlace';
import { MapCreateStationary } from './MapCreateStationary';
import { MapEditItem } from './MapEditItem';
import { MapEditList } from './MapEditList';
import { MapEditOverlay } from './MapEditOverlay';
import { MapEditTabs } from './MapEditTabs';
import { MapEditTitle } from './MapEditTitle';

export const MapEditMenu = forwardRef(function MapEditMenu(
  {
    app,
    showInfo,
    mapId,
    mapLayers,
    mapName,
    mapDescription,
    mapKeywords,
    isStatic,
    placeList,
    gatewayList,
    stationaryList,
    placeData,
    gatewayData,
    assetData,
    stationaryData,
    selected,
    clickedItem,
    newObjCreated,
    newScaleCreated,
    showMore,
    setShowInfo,
    setMapName,
    setMapKeywords,
    setMapDescription,
    setMapImg,
    setSelected,
    setCreatePlace,
    setCreateGateway,
    setCreateStationary,
    setCreateScale,
    setScaleLength,
    setAddScale,
    setObjectId,
    setObjectName,
    setObjectKeywords,
    setObjectFloor,
    setObjectOnline,
    setAddToMap,
    setDeleteItem,
    setUpdateItem,
    setRemoveTempItem,
    setClickedItem,
    fetchPlaceData,
    fetchGatewayData,
    fetchStationaryData,
    reset,
    saveMap,
    setShowMore,
  },
  ref,
) {
  const [listData, setListData] = useState<any>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedGateway, setSelectedGateway] = useState<number>(null);
  const [curGateways, setCurGateways] = useState<any[]>([]);

  let infoClasses = classNames({
    'info-container': !showInfo,
    'info-container-hide': showInfo,
  });

  let collapseClasses = classNames({
    'collapse-button': showInfo,
    'collapse-button-hide': !showInfo,
  });

  //Keeps track of gateways placed so duplicates are not possible
  useEffect(() => {
    if (gatewayList) {
      setCurGateways(
        gatewayList.features.map((item) => {
          return parseInt(item.properties['Id']);
        }),
      );
    }
  }, [gatewayList]);

  //Set data to display in list
  useEffect(() => {
    setIsCreating(false);
    switch (selected) {
      case 'place':
        setListData(placeData);
        break;
      case 'asset':
        setListData(assetData);
        break;
      case 'gateway':
        setListData(gatewayData);
        break;
      case 'stationary':
        setListData(stationaryData);
        break;
      case 'overlay':
        setListData(null);
        break;
      case '':
        setListData(null);
        break;
    }
  }, [placeData, assetData, gatewayData, stationaryData, selected]);

  return (
    <>
      <div className={collapseClasses}>
        <Card style={{ backgroundColor: 'var(--primary)' }}>
          <Button
            variant="dark"
            type="submit"
            className="collapse-card"
            style={{
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              width: '50px',
            }}
            onClick={() => {
              setShowInfo(!showInfo);
            }}
          >
            <i
              className={'sonarIcon sonarIcon-right-arrow align-sub fs-lg'}
              style={{
                transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.5s ease-in-out',
                color: 'var(--white)',
              }}
            />
          </Button>
        </Card>
      </div>
      <div className={infoClasses} ref={ref}>
        <Card className="d-flex flex-row" style={{ backgroundColor: 'var(--primary)' }}>
          <div className="collapse-container">
            <Button
              variant="dark"
              type="submit"
              className="collapse-card"
              style={{
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                width: '50px',
              }}
              onClick={() => {
                setShowInfo(!showInfo);
              }}
            >
              <i
                className={'sonarIcon sonarIcon-right-arrow align-sub fs-lg'}
                style={{
                  transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.5s ease-in-out',
                  color: 'var(--white)',
                }}
              />
            </Button>
          </div>
          <div className="menu-bar">
            <MapEditTitle
              app={app}
              mapId={mapId}
              mapName={mapName}
              setMapName={(e) => setMapName(e.target.value)}
              selected={selected}
              reset={() => reset()}
              saveMap={() => saveMap()}
            />
            <MapEditTabs
              setClickedItem={(e) => setClickedItem(e)}
              selected={selected}
              setSelected={(e) => setSelected(e)}
            />
          </div>
        </Card>
        <Card
          className={selected === '' ? 'object-list' : 'object-list-expand'}
          style={{
            backgroundColor: 'var(--light)',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          <MapEditOverlay
            visible={selected === 'overlay' && showMore === null}
            keywords={mapKeywords}
            description={mapDescription}
            isStatic={isStatic}
            newScaleCreated={newScaleCreated}
            setAddScale={(e) => setAddScale(e)}
            setSelected={(e) => setSelected(e)}
            setKeywords={(e) => setMapKeywords(e)}
            setDescription={(e) => setMapDescription(e)}
            setImage={(e) => setMapImg(e)}
            setCreateScale={(e) => setCreateScale(e)}
            setScaleLength={(e) => setScaleLength(e)}
            setRemoveTempItem={(e) => setRemoveTempItem(e)}
          />
          <MapEditList
            key="place"
            type="place"
            data={placeData}
            listData={placeList}
            visible={selected === 'place' && showMore === null && isCreating === false}
            setClickedItem={(e) => setClickedItem(e)}
            setViewItem={(e) => setShowMore(e)}
            setSelected={(e) => setSelected(e)}
            fetchData={(e) => {
              fetchPlaceData(e);
            }}
            setIsCreating={(e) => setIsCreating(e)}
          />
          <MapEditList
            key="gateway"
            type="gateway"
            data={gatewayData}
            listData={gatewayList}
            visible={selected === 'gateway' && showMore === null && isCreating === false}
            setClickedItem={(e) => setClickedItem(e)}
            setViewItem={(e) => setShowMore(e)}
            setSelected={(e) => setSelected(e)}
            fetchData={(e) => {
              fetchGatewayData(e);
            }}
            setIsCreating={(e) => setIsCreating(e)}
          />
          <MapEditList
            key="stationary"
            type="stationary"
            data={stationaryData}
            listData={stationaryList}
            visible={selected === 'stationary' && showMore === null && isCreating === false}
            setClickedItem={(e) => setClickedItem(e)}
            setViewItem={(e) => setShowMore(e)}
            setSelected={(e) => setSelected(e)}
            fetchData={(e) => {
              fetchStationaryData(e);
            }}
            setIsCreating={(e) => setIsCreating(e)}
          />
          {showMore !== null && clickedItem !== null && (
            <MapEditItem
              clickedItem={clickedItem}
              selected={selected}
              mapName={mapName}
              mapLayers={mapLayers}
              setShowMore={(e) => setShowMore(e)}
              setUpdateItem={(e) => setUpdateItem(e)}
              setDeleteItem={(e) => setDeleteItem(e)}
            />
          )}
          {isCreating && selected === 'place' && (
            <MapCreatePlace
              selected={selected}
              newObjCreated={newObjCreated}
              setIsCreating={(e) => setIsCreating(e)}
              setCreatePlace={(e) => setCreatePlace(e)}
              setObjectName={(e) => setObjectName(e)}
              setObjectKeywords={(e) => setObjectKeywords(e)}
              setAddToMap={(e) => setAddToMap(e)}
              setRemoveTempItem={(e) => setRemoveTempItem(e)}
            />
          )}
          {isCreating && selected === 'gateway' && (
            <MapCreateGateway
              selected={selected}
              newObjCreated={newObjCreated}
              curGateways={curGateways}
              selectedGateway={selectedGateway}
              setCurGateways={(e) => setCurGateways(e)}
              setSelectedGateway={(e) => setSelectedGateway(e)}
              setIsCreating={(e) => setIsCreating(e)}
              setCreateGateway={(e) => setCreateGateway(e)}
              setObjectId={(e) => setObjectId(e)}
              setObjectName={(e) => setObjectName(e)}
              setObjectFloor={(e) => setObjectFloor(e)}
              setObjectKeywords={(e) => setObjectKeywords(e)}
              setObjectOnline={(e) => setObjectOnline(e)}
              setAddToMap={(e) => setAddToMap(e)}
              setRemoveTempItem={(e) => setRemoveTempItem(e)}
            />
          )}
          {isCreating && selected === 'stationary' && (
            <MapCreateStationary
              selected={selected}
              newObjCreated={newObjCreated}
              setIsCreating={(e) => setIsCreating(e)}
              setCreateStationary={(e) => setCreateStationary(e)}
              setObjectId={(e) => setObjectId(e)}
              setObjectName={(e) => setObjectName(e)}
              setObjectFloor={(e) => setObjectFloor(e)}
              setObjectKeywords={(e) => setObjectKeywords(e)}
              setObjectOnline={(e) => setObjectOnline(e)}
              setAddToMap={(e) => setAddToMap(e)}
              setRemoveTempItem={(e) => setRemoveTempItem(e)}
            />
          )}
        </Card>
      </div>
    </>
  );
});
