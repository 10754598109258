import './editMap.scss';
import './gatewayEditRow.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Search from '../../../../components/search';

class GatewayEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floor: this.props.gateway.floor,
      gateway: this.props.gateway.gateway,
    };

    this.onSearchItemSelected = this.onSearchItemSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSearchItemSelected(item) {
    item.gatewayId = `${item.id}`; // temporary fix until property comes back from API with id
    this.setState({
      gateway: item,
    });
  }

  handleChange(event) {
    this.setState({
      floor: event.target.value,
    });
  }

  onConfirm() {
    this.props.onEditCompleted('gateways', {
      index: this.props.gateway.index,
      gatewayId: this.state.gateway.gatewayId,
      location: this.props.gateway.location,
      floor: Number(this.state.floor),
      gateway: this.state.gateway,
      isVisible: true,
    });
  }

  onCancel() {
    if (!this.props.gateway.gateway) {
      this.props.onAddCanceled('gateways', this.props.gateway.index);
      this.props.gateway.location = null;
    } else {
      this.props.onEditCompleted('gateways', this.props.gateway);
    }
  }

  render() {
    return (
      <div className="align-items-center w-100 ps-4 pe-4 mt-4 edit-maps-group">
        <div className="form-group">
          <label className="form-label">Search Gateway *</label>
          <Search
            items={this.props.searchItems}
            onSelect={this.onSearchItemSelected}
            exceptIds={this.props.excludedSearchItems}
            onChange={this.props.onSearchFilterChanged}
            selected={this.state.gateway}
            title="deviceId"
            subTitle="name"
            showInitial={this.state.gateway !== null}
          />
        </div>
        <br />
        <div className="form-group w-25">
          <label className="form-label">Floor *</label>
          <input
            className="form-control form-control-sm text-center"
            value={this.state.floor}
            onChange={this.handleChange}
          />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <span className="btn" title="Set Coordinates">
            <button
              className={`btn btn-primary ${
                this.props.gateway.location ? 'coordinates-set' : 'set-coordinates'
              }`}
              type="button"
              onClick={() => this.props.onEditMap(this.props.gateway)}
            >
              {this.props.gateway.location
                ? `Coordinates Set: ${this.props.gateway.location.x}, ${this.props.gateway.location.y}`
                : 'Set Map Coordinates'}
            </button>
          </span>
        </div>
        <br />
        <div className="d-flex justify-content-center mt-5 edit-map-submit-cancel-container">
          <button
            onClick={this.state.gateway && this.props.gateway.location ? this.onConfirm : undefined}
            type="button"
            className={`btn btn-primary pe-4 ps-4 ${
              this.state.gateway && this.props.gateway.location ? 'btn-enabled' : 'btn-disabled'
            }`}
            title="Confirm"
          >
            Confirm
          </button>
          <button
            onClick={this.onCancel}
            type="button"
            className="btn btn-primary pe-4 ps-4 btn-danger"
            title="Cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

GatewayEditRow.propTypes = {
  gateway: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
  onEditMap: PropTypes.func,
};

export default GatewayEditRow;
