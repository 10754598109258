import { LoadingButton } from '@components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select2 from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import validator from 'validator';
import DarkModeToggle from '../../components/checkboxes/DarkModeToggle';
import { AssetFilterContainer } from '../../containers';
import { ThemeContext } from '../../context/ThemeContext';
import { store } from '../../redux';
import { appService } from '../../services';

const MySwal = withReactContent(Swal);

interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  defaultApplication: {
    label: string;
    value: number;
  };
  maximumRecentlyViewedItems: number;
  role: string;
  applications: string[];
  assetFilters: string[];
  temperatureUnit: string;
  distanceUnit: string;
}

interface UserSettingsProps {
  userProfile: IUser;
  updateUser: Function;
  isLoading: boolean;
  isError: boolean;
  onDone(): void;
}

function useOutsideAlerter(ref, isModified, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      const selectedNav = event.target.closest('a');
      if (ref.current && !ref.current.contains(event.target) && !isModified) {
        callBack();
      } else if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.includes('swal2')
      ) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure you want to leave?',
          text: 'Your changes will not be saved.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              window.location = selectedNav.attributes.href.value;
            }
          } else {
            MySwal.close();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModified]);
}

export const UserSettingsForm: React.FC<UserSettingsProps> = ({
  userProfile,
  updateUser,
  isLoading,
  isError,
  onDone,
}) => {
  const { data: apps } = useQuery(['appselector-applications'], () => appService.getApps(2000));
  const { theme, setTheme } = useContext(ThemeContext);
  const wrapperRef = useRef(null);
  const formRef = useRef(null);
  const [userPayload, setUserPayload] = useState<IUser>(userProfile);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [appVal, setAppVal] = useState<number>(0);
  const [appName, setAppName] = useState<any>('Select a Default Business Application');
  const defaultApp = apps?.find((app) => app.id === userProfile.defaultApplication);

  useOutsideAlerter(wrapperRef, isModified, onDone);
  useEffect(() => {
    if (isSubmitting) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [userPayload]);

  useEffect(() => {
    setAppName(defaultApp?.name || 'Select a Default Business Application');
  }, [userProfile, defaultApp]);

  const handleChange = (e) => {
    if (e.target?.name === 'phoneNumber') {
      const isPhoneNumberValid = (value) => {
        const strippedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
        return (
          strippedValue.length >= 10 && validator.isMobilePhone(value, 'any', { strictMode: false })
        );
      };
      if (!isPhoneNumberValid(e.target.value) && e.target.value !== '') {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    setIsModified(true);
    setIsSubmitting(false);
    setUserPayload({
      ...userPayload,
      assetFilters: JSON.parse(localStorage.getItem('assetFilters')),
      [e.target?.name ?? e?.name]: e?.value ?? e.target.value,
    });
  };

  const handleFilterChange = (filters, disabled, modified) => {
    modified ? setIsModified(true) : setIsModified(false);
    setIsDisabled(disabled);
    setIsSubmitting(false);
    setUserPayload({
      ...userPayload,
      assetFilters: filters,
    });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  const handleTabClick = (tab) => {
    tab === activeTab ? setActiveTab(-1) : setActiveTab(tab);
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      const appPicked = apps.find((obj) => obj.id === Number(selectedOption.value));
      setAppVal(appPicked?.id === undefined ? 0 : appPicked?.id);
      setAppName(appPicked?.name === undefined ? 'Select Application' : appPicked?.name);
    } else {
      // Handle the case when no option is selected
      setAppVal(0);
      setAppName('Select Application');
    }

    // Call the handleChange function if needed
    handleChange(selectedOption);
  };

  // Toggle the theme based on the toggle switch state
  const handleToggle = (isDarkMode) => {
    setTheme(isDarkMode ? 'dark' : 'light');
  };

  return (
    <div className="h-100 w-100" ref={wrapperRef}>
      <Form
        id={'userPrefs'}
        ref={formRef}
        onSubmit={(e) => {
          setIsModified(false);
          e.preventDefault();
          updateUser({ ...userPayload });
          setIsSubmitting(true);
        }}
      >
        {/* User Info */}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" onClick={() => handleTabClick(0)}>
            <Accordion.Header>User Info</Accordion.Header>
            <Accordion.Body>
              <Card.Body>
                <Form.Group>
                  <Form.Label className="user-info-labels">Email</Form.Label>
                  <Form.Control
                    disabled
                    id={'user-settings-email-input'}
                    name="email"
                    type="email"
                    defaultValue={userProfile.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="user-info-labels pt-2">First Name</Form.Label>
                  <Form.Control
                    id={'user-settings-firstName-input'}
                    type="text"
                    name="firstName"
                    defaultValue={userProfile.firstName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="user-info-labels pt-2">Last Name</Form.Label>
                  <Form.Control
                    id={'user-settings-lastName-input'}
                    type="text"
                    name="lastName"
                    defaultValue={userProfile.lastName}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="user-info-labels pt-2">Phone Number</Form.Label>
                  <Form.Control
                    id={'user-settings-phoneNumber-input'}
                    type="text"
                    name="phoneNumber"
                    defaultValue={userProfile.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Label className="user-info-labels pt-2">
                  Default Business Application
                </Form.Label>
                <Select2
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'var(--c-primary)',
                    },
                  })}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    option: (styles, state) => ({
                      ...styles,
                      backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
                      color: state.isSelected ? 'white' : 'black',
                      '&:hover': {
                        backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                      fontWeight: '500',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: 'black',
                    }),
                  }}
                  options={[
                    // { value: '', label: 'No Option', name: 'DefaultApplicationId' },
                    ...(Array.isArray(apps)
                      ? apps.map((app) => ({
                          value: app.id,
                          label: app.name,
                          name: 'DefaultApplicationId',
                        }))
                      : []),
                  ]}
                  defaultValue={userProfile.defaultApplication}
                  name="defaultApplication"
                  placeholder={appName}
                  onChange={handleSelectChange}
                />
                <Form.Group>
                  <Form.Label className="user-info-labels pt-2">
                    Maximum # Recently Viewed Items
                  </Form.Label>
                  <Form.Control
                    id={'user-settings-maximumRecentlyViewedItems-input'}
                    type="number"
                    name="maximumRecentlyViewedItems"
                    defaultValue={userProfile.maximumRecentlyViewedItems}
                    onChange={handleChange}
                    min={1}
                  />
                  <Form.Text className="text-muted">
                    This controls how many recently viewed items you'll see on the Home screen.
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <LoadingButton
                    disabled={isDisabled}
                    type="submit"
                    text="Save"
                    isLoading={isLoading}
                    className="mt-3"
                  />
                </Form.Group>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
          {/* Default Asset Filters */}
          <Accordion.Item eventKey="1" onClick={() => handleTabClick(1)}>
            <Accordion.Header as={Card.Header}>Default Asset Filters</Accordion.Header>
            <Accordion.Body>
              <Card.Body>
                <Form.Group>
                  <AssetFilterContainer
                    onChange={handleFilterChange}
                    defaultValue={userProfile.assetFilters}
                    submit={isSubmitting}
                    onSubmit={handleSubmit}
                  />
                </Form.Group>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
          {/* Unit Preferences  */}
          <Accordion.Item eventKey="2" onClick={() => handleTabClick(2)}>
            <Accordion.Header as={Card.Header}>Units Preference</Accordion.Header>
            <Accordion.Body>
              <Card.Body>
                {/* Measurement */}
                <Form.Label className="user-info-labels pt-2">Measurement</Form.Label>
                <Select2
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'var(--c-primary)',
                    },
                  })}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    option: (styles, state) => ({
                      ...styles,
                      backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
                      color: state.isSelected ? 'white' : 'black',
                      '&:hover': {
                        backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                      fontWeight: '500',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: 'black',
                    }),
                  }}
                  options={[
                    { value: 'Imperial', label: 'Imperial', name: 'distanceUnit' },
                    { value: 'Metric', label: 'Metric', name: 'distanceUnit' },
                  ]}
                  defaultValue={userProfile.distanceUnit}
                  name="distanceUnit"
                  placeholder={userProfile.distanceUnit}
                  onChange={handleSelectChange}
                />
                {/* Temperature */}
                <Form.Label className="user-info-labels pt-2">Temperature</Form.Label>
                <Select2
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'var(--c-primary)',
                    },
                  })}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    option: (styles, state) => ({
                      ...styles,
                      backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
                      color: state.isSelected ? 'white' : 'black',
                      '&:hover': {
                        backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                      fontWeight: '500',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: 'black',
                    }),
                  }}
                  options={[
                    { value: 'Celsius', label: 'Celsius', name: 'temperatureUnit' },
                    { value: 'Fahrenheit', label: 'Fahrenheit', name: 'temperatureUnit' },
                  ]}
                  defaultValue={userProfile.temperatureUnit}
                  name="defaultTemperature"
                  placeholder={userProfile.temperatureUnit}
                  onChange={handleSelectChange}
                />

                <Form.Group>
                  <LoadingButton
                    disabled={isDisabled}
                    type="submit"
                    text="Save"
                    isLoading={isLoading}
                    className="mt-3"
                  />
                </Form.Group>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
          {/* Dark Light Mode */}
          <Accordion.Item eventKey="3" onClick={() => handleTabClick(3)}>
            <Accordion.Header>Theme</Accordion.Header>
            <Accordion.Body>
              <Card.Body>
                <Form.Group>
                  <DarkModeToggle onToggle={handleToggle} on={theme === 'dark'} />
                </Form.Group>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Form>
    </div>
  );
};
