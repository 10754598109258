import './inputs.scss';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

export function BatteryVal({ key, valid, invalid, disabled, initFilter, onFilterChange }) {
  const [batteryComparison, setBatteryComparison] = useState(0); // 0 for greater than, 1 for less than
  const [batteryValue, setBatteryValue] = useState('');

  useEffect(() => {
    if (batteryComparison !== -1 && batteryValue !== '') {
      const battery = `batteryValue(${batteryComparison}, ${batteryValue})`;
      onFilterChange(battery);
    }
  }, [batteryComparison, batteryValue, onFilterChange]);

  return (
    <>
      <p>Battery Value is:</p>

      <Col sm="12" className="mb-3">
        <InputGroup>
          <Form.Select
            defaultValue={initFilter.length > 0 ? initFilter.split(', ')[0] : 0}
            name="batteryValueGreaterLess"
            as="select"
            isValid={valid}
            isInvalid={invalid}
            disabled={disabled}
            onChange={(e) => {
              setBatteryComparison(parseInt(e.target.value));
            }}
          >
            <option id={'0'} key={'greater'} value={0}>
              Greater than
            </option>
            <option id={'1'} key={'less'} value={1}>
              Less than
            </option>
          </Form.Select>
          <Form.Control
            key={key}
            id="battery"
            type="number"
            name="batteryValueNumber"
            isValid={valid}
            isInvalid={invalid}
            disabled={disabled}
            placeholder="0-100"
            min={0}
            max={100}
            defaultValue={initFilter.length > 0 ? initFilter.split(', ')[1] : ''}
            onChange={(e) => {
              let value = e.target.value;

              // Remove leading zeros if the number is not just '0'
              if (value !== '0') {
                value = value.replace(/^0+/, '');
              }

              // Ensure the entered value doesn't include a '-' and doesn't start with it
              if (!value.includes('-') && !value.startsWith('-')) {
                // Ensure the entered value is within the specified range (0-100)
                if (
                  !isNaN(Number(value)) &&
                  value !== '' &&
                  parseInt(value) >= 0 &&
                  parseInt(value) <= 100
                ) {
                  setBatteryValue(value);
                } else {
                  // Handle cases where the input is not a valid number or outside the range
                  setBatteryValue('');
                }
              } else {
                // Handle cases where the input contains or starts with '-'
                setBatteryValue('');
              }
            }}
            onKeyDown={(e) => {
              // Prevent the hyphen character from being typed
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
          />
        </InputGroup>
      </Col>
    </>
  );
}
