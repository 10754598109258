import '../../MapMenu.scss';
import { capitalize } from '@utils';
import { getFormattedDateTime } from '@utils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon, Text } from '../../../../components';
import { V2FilterMapList } from '../../../AssetFilter/functions/V2FilterMapList';

export function MapEditList({
  data,
  type,
  listData,
  fetchData,
  visible,
  setClickedItem,
  setViewItem,
  setSelected,
  setIsCreating,
}) {
  const [title, setTitle] = useState<string>(null);
  const [sort, setSort] = useState<any>({ field: 'Name', ascending: true });
  const [search, setSearch] = useState<string>('');
  const [andOr, setAndOr] = useState<string>('and');
  const [assetFilters, setAssetFilters] = useState<any>([]);
  const history = useHistory();
  const isFirstRender = useRef(true);

  const debounceFetchData = useCallback(
    debounce((assetFilters, andOr, sort, search, type) => {
      fetchData(V2FilterMapList(assetFilters, andOr, sort, search), type);
    }, 500),
    [],
  );

  useEffect(() => {
    if (type) {
      type === 'stationary' ? setTitle('Stationary Assets') : setTitle(capitalize(type) + 's');
    } else {
      setTitle(null);
    }
  }, [type]);

  useEffect(() => {
    debounceFetchData(assetFilters, andOr, sort, search, type);
  }, [type, assetFilters, andOr, sort, search]);

  //Handle asset filter data changes
  const handleFilterChange = (e, type) => {
    if (JSON.stringify(e) !== JSON.stringify(assetFilters)) {
      setAssetFilters(e);
    }
    if (type !== andOr) {
      setAndOr(type);
    }
  };

  return (
    <div className={visible ? 'object-wrapper-edit' : 'object-wrapper-none'}>
      <div className={visible ? 'pinned-controls-edit' : 'pinned-controls-none'}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <Text className="title-text fw-bold fs-md me-3">{title ? title : ''}</Text>
          <Icon
            name={'add'}
            title="Close"
            className="sonarIcon-rotate-45 align-sub nav-text-icon"
            variant="light"
            disabled={false}
            size="md"
            onClick={() => {
              setSelected(null);
            }}
          />
        </div>
        {type !== 'overlay' && (
          <div className="d-flex flex-row align-items-center justify-content-between"></div>
        )}
        <div className="d-flex flex-row align-items-center justify-content-between title-row mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <Text className="title-text fw-bold fs-md me-2">Create New</Text>
            <Icon
              name={'add'}
              title={`Add ${capitalize(type)}`}
              className="align-sub primary-icon"
              variant="light"
              disabled={false}
              size="md"
              onClick={() => {
                setIsCreating(true);
              }}
            />
          </div>
        </div>
      </div>
      {listData && (
        <div className="list-container-edit">
          {listData?.features.map((feature, index) => {
            return (
              <div id={feature.properties.Id} key={index}>
                <div className="list-item">
                  <div className="list-info">
                    <div className="name-col">{feature.properties.Name}</div>
                    {feature.properties.LastCheckIn && (
                      <div className="checkin-col">
                        {getFormattedDateTime(feature.properties.LastCheckIn)}
                      </div>
                    )}
                  </div>
                  <div className="btns-col">
                    {(type === 'asset' || type === 'stationary') && (
                      <Button
                        title="Go to Asset"
                        className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                        variant="light"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          history.push('/assets/' + feature.properties.Id);
                          e.stopPropagation();
                        }}
                      >
                        <i className={`sonarIcon sonarIcon-open-in fs-md`} />
                      </Button>
                    )}
                    <Button
                      title="Show more"
                      className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                      variant="light"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        let newData = Object.values(data._layers);
                        setViewItem(feature);
                        setClickedItem(
                          newData.find((item) => {
                            return (item as any).feature.properties.Id === feature.properties.Id;
                          }),
                        );
                        e.stopPropagation();
                      }}
                    >
                      <i className={`sonarIcon sonarIcon-h-dots-1 fs-md`} />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
