import './BeaconTypeScreen.scss';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Keywords, Loading } from '../../components';
import { Api } from '../../services';

const cancelToken = Api.CancelToken;
const source = cancelToken.source();

async function fetchTypes() {
  const { data } = await Api.get(`/beacon-types?top=100000`, {
    cancelToken: source.token,
  });
  return data;
}

const BeaconImage = ({ type }) => {
  const defaultUrl =
    'https://thinaer.io/wp-content/uploads/2020/10/Thinaer_Wordmark_DarkBlue_FINAL-01.svg';
  const [url, setUrl] = useState(type.imageUrl);
  return (
    <Card.Img
      onError={() => setUrl(defaultUrl)}
      variant="top"
      src={url}
      alt={`${type.name} by ${type.manufacturer}`}
    />
  );
};

function BeaconTypesScreen() {
  const res = useQuery('beaconTypes', fetchTypes);

  if (res.isLoading) return <Loading />;

  return (
    <div className="d-flex flex-row flex-wrap">
      {res.data.items.map((type) => {
        return (
          <Card key={type.id} className="beacon-type-card m-5">
            <BeaconImage type={type} />

            <Card.Body>
              <Card.Title>{type.name}</Card.Title>
              <Card.Text>{`Database ID: ${type.id}`}</Card.Text>
              <Card.Text>{`Model: ${type.model}`}</Card.Text>
              <Card.Text>{`Manufacturer: ${type.manufacturer}`}</Card.Text>
              <Card.Text>
                <a href={type.infoUrl}>{type.infoUrl}</a>
              </Card.Text>
            </Card.Body>

            <Card.Footer>
              <Keywords
                initKeywords={type.abilities.map((ability) => ability.name)}
                canDeleteKeywords={false}
              />
            </Card.Footer>
          </Card>
        );
      })}
    </div>
  );
}

export default BeaconTypesScreen;
